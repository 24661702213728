import React, { useCallback } from 'react';
import { DesktopBannerMaker } from '../shared/banners';
import { useBannerContext } from 'providers/BannerProvider';

export default function DesktopBooksBanner() {
  const { bannersToShowInfo } = useBannerContext();

  const getHighPriorityFrontBanner = useCallback(() => {
    if (bannersToShowInfo?.desktopFrontBannerName === 'desktopBannerMaker') {
      return <DesktopBannerMaker source="Book" />;
    }

    return null;
  }, [bannersToShowInfo]);

  if (!bannersToShowInfo) return null;

  const banner = getHighPriorityFrontBanner();
  if (!banner) return null;

  return banner;
}
