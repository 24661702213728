import React, { useCallback, useEffect } from 'react';
import MobileFrontBanner from 'modules/banner/main-banner/MobileFrontbanner';
import { styled } from 'styles';
import { useShouldShowUploadFileDialog } from 'modules/main/hooks/useShouldShowUploadFileDialog';
import { useUploadTracker } from '../dialogs/upload-file/hooks/useUploadTracker';
import NewThemesMenu from '../components/home/theme/NewThemesMenu';
import NewHomeAlert from '../components/home/new-home-alert/NewHomeAlert';

export default function HomeMobile({
  generalThemes,
  // privateThemes,
  disciplines,
  selectedTheme,
  setSelectedTheme,
  studyPlans,
  mvpLists,
  listsWithStatement,
  lists,
  onListUpdated,
}) {
  // const { setDialog } = useDialogContext();
  // const { trackEvent } = useTracker();
  const { isUserElegibleToUploadFile } = useShouldShowUploadFileDialog();
  // const { user } = useUserContext();

  const {
    trackViewUploadToHotjar,
    //trackClickUploadToHotjar,
  } = useUploadTracker();

  const onSelectTheme = useCallback(
    theme => {
      if (selectedTheme && theme.id === selectedTheme.id) {
        setSelectedTheme(null);
      } else {
        setSelectedTheme(theme);
      }
    },
    [setSelectedTheme, selectedTheme]
  );

  const shouldShowUploadFileButton = isUserElegibleToUploadFile();

  // const openUploadFileDialog = useCallback(() => {
  //   trackEvent('Listas: Clicou para Enviar Prova/Lista', { Origem: 'Home' });
  //   trackClickUploadToHotjar();
  //   setDialog(<UploadFileDialog theme={selectedTheme || generalThemes[0]} />);
  // }, [
  //   setDialog,
  //   trackEvent,
  //   selectedTheme,
  //   trackClickUploadToHotjar,
  //   generalThemes,
  // ]);

  useEffect(() => {
    if (shouldShowUploadFileButton) trackViewUploadToHotjar();
  }, [shouldShowUploadFileButton, trackViewUploadToHotjar]);

  return (
    <>
      <MainContainer anyThemeSelected={selectedTheme}>
        <h1>Comece escolhendo uma matéria:</h1>
        {/* {shouldShowUploadFileButton && (
          <UploadFileMobileButton
            onClick={openUploadFileDialog}
            anyThemeSelected={selectedTheme}
          >
            <MdUploadFile />
            ENVIAR PROVAS/LISTAS DA {user?.university?.acronym}
          </UploadFileMobileButton>
        )} */}
        {/* {privateThemes.length > 0 && (
          <PrivateThemesMenu
            privateThemes={privateThemes}
            disciplines={disciplines}
            onSelectTheme={onSelectTheme}
            selected={selectedTheme}
            studyPlans={studyPlans}
          />
        )} */}
        <NewThemesMenu
          themes={generalThemes}
          selected={selectedTheme}
          onSelectTheme={onSelectTheme}
          disciplines={disciplines}
          studyPlans={studyPlans}
          mvpLists={mvpLists}
          listsWithStatement={listsWithStatement}
          lists={lists}
          onListUpdated={onListUpdated}
        />
      </MainContainer>
      <MobileFrontBanner />
      <NewHomeAlert />
    </>
  );
}

const MainContainer = styled.div`
  margin: 100px auto 0 auto;
  width: ${({ anyThemeSelected }) => (anyThemeSelected ? '100%' : '95%')};

  & > h1 {
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
  }
`;

// const UploadFileMobileButton = styled.div`
//   border: 2px solid #36aaad;
//   font-size: 1em;
//   font-weight: bold;
//   border-radius: 9px;
//   background-color: #fff;

//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 10px;
//   margin: 8px ${({ anyThemeSelected }) => (anyThemeSelected ? '10px' : '0px')};

//   svg {
//     font-size: 1.8em;
//     color: #36aaad;
//   }
// `;
