import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';
import { PiPath } from 'react-icons/pi';

import { DisciplineCard } from 'modules/main/components/themes-page/discipline';
import { StudyPlanCard } from 'modules/main/components/themes-page/study-plan';
import { useUniversityContext } from 'providers/UniversityProvider';
import OtherSubjectsButton from '../../themes-page/buttons/OtherSubjectsButton';
import { useShouldShowUploadFileDialog } from 'modules/main/hooks/useShouldShowUploadFileDialog';
import { useProfessor } from 'modules/professors/hooks/useProfessor';
import SectionHeader from '../generic/SectionHeader';
import NewSubjectGroupsContainer from '../subject/NewSubjectGroupsContainer';
import { useUploadTracker } from 'modules/main/dialogs/upload-file/hooks/useUploadTracker';
import BuildStudyPlan from '../../themes-page/guide/BuildStudyPlan';
import UploadFileCard from '../../themes-page/upload-file/UploadFileCard';

export default function NewThemeContents({
  selectedTheme,
  disciplines,
  studyPlans,
  mvpLists,
  listsWithStatement,
}) {
  const {
    university,
    universityPronoumn,
    isFromPrivateUniversityCampaign,
  } = useUniversityContext();
  const { isElegibleToUploadFile } = useShouldShowUploadFileDialog();
  const { trackViewUploadToHotjar } = useUploadTracker();
  const { showUserProfessors } = useProfessor();

  if (disciplines)
    disciplines = disciplines.filter(d => d.theme.id === selectedTheme?.id);

  if (studyPlans)
    studyPlans = studyPlans.filter(sp => sp.themeId === selectedTheme?.id);

  if (mvpLists)
    mvpLists = mvpLists.filter(
      l => l.edition.book.themeId === selectedTheme?.id
    );

  if (listsWithStatement)
    listsWithStatement = listsWithStatement.filter(
      l => l.theme.id === selectedTheme?.id
    );

  const shouldShowSendFileCard = isElegibleToUploadFile(selectedTheme);

  const checkIfSelectedThemeIsPrivate = useCallback(() => {
    const themeHasAtLeastOnePrivateSubject =
      selectedTheme.studyTracks.length > 0;

    return isFromPrivateUniversityCampaign && themeHasAtLeastOnePrivateSubject;
  }, [isFromPrivateUniversityCampaign, selectedTheme]);

  const disciplineTitle = useCallback(() => {
    if (checkIfSelectedThemeIsPrivate())
      return `${selectedTheme.name} e Matemática Básica ao mesmo tempo`;

    if (
      university &&
      university.acronym &&
      university.acronym != 'Selecione a universidade'
    ) {
      const pronoum = universityPronoumn === 'do' ? 'pro' : 'pra';
      return `Guias de estudo personalizados ${pronoum} ${university.acronym}`;
    }

    return `${selectedTheme.name} da sua faculdade`;
  }, [
    checkIfSelectedThemeIsPrivate,
    university,
    universityPronoumn,
    selectedTheme,
  ]);

  const showDisciplineInfos = useCallback(() => {
    if (mvpLists?.length > 0) return true;
    if (listsWithStatement?.length > 0) return true;
    if (disciplines?.length > 0) return true;
    if (studyPlans?.length > 0) return true;
    if (shouldShowSendFileCard) return true;
    if (!showUserProfessors()) return true;

    return false;
  }, [
    disciplines,
    listsWithStatement,
    mvpLists,
    shouldShowSendFileCard,
    showUserProfessors,
    studyPlans,
  ]);

  useEffect(() => {
    if (shouldShowSendFileCard) trackViewUploadToHotjar();
  }, [shouldShowSendFileCard, trackViewUploadToHotjar]);

  return (
    <Container>
      {university && showDisciplineInfos() && (
        <>
          <SectionHeader
            theme={selectedTheme}
            icon={<PiPath size={20} />}
            text={disciplineTitle()}
          />
          <CardsContainer>
            {/* {isFromPrivateUniversityCampaign &&
              selectedTheme.studyTracks.map(st => (
                <StudyTrackCard
                  theme={selectedTheme}
                  studyTrack={st}
                  key={st.id}
                />
              ))} */}

            {disciplines?.map(d => (
              <DisciplineCard theme={selectedTheme} discipline={d} key={d.id} />
            ))}
            {studyPlans?.map(sp => (
              <StudyPlanCard
                theme={selectedTheme}
                studyPlan={sp}
                key={sp.id}
                privateThemeColor={checkIfSelectedThemeIsPrivate()}
              />
            ))}
            <BuildStudyPlan
              theme={selectedTheme}
              privateThemeColor={checkIfSelectedThemeIsPrivate()}
            />
            {shouldShowSendFileCard && (
              <UploadFileCard disciplines={disciplines} theme={selectedTheme} />
            )}
          </CardsContainer>
        </>
      )}
      {checkIfSelectedThemeIsPrivate() ? (
        <OtherSubjectsButton theme={selectedTheme} />
      ) : (
        <NewSubjectGroupsContainer theme={selectedTheme} />
      )}
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
  position: relative;
  border-radius: 10px;
  padding: 24px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
`;

const CardsContainer = styled.div`
  padding: 0 5px;
  margin-bottom: 30px;
  flex-wrap: wrap;
  display: flex;
`;
