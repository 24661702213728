import React, { useCallback, useEffect, useMemo } from 'react';
import { styled, media } from 'styles';
import { FaPen } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

import {
  GuideHeader,
  LecturesCards,
  SubjectsCards,
  ListsCategoriesCards,
  BooksCards,
  CardsContent,
} from '../components';
import { Loading, Error } from 'modules/shared/components/generic';
import { useGuide } from '../hooks';
import { useTracker, useUTM } from 'modules/shared/hooks';
import { useUserContext } from 'providers/UserProvider';
import {
  useUniversityContext,
  getAcronymByUniversityName,
} from 'providers/UniversityProvider';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import useRenderDisciplinePageDialog from '../hooks/useRenderDisciplinePageDialog';
import BookListCards from '../components/BookListCards';
import { useProfessor } from 'modules/professors/hooks/useProfessor';
import CreateUserProfessorDialog from 'modules/professors/dialogs/CreateUserProfessorDialog';
import { useDialogContext } from 'providers/DialogProvider';
import { useFeatureFlagContext } from 'providers/FeatureFlagProvider';

export default function DisciplinePage() {
  const { disciplineId, guideId } = useParams();
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();
  const { user } = useUserContext();
  const { universityPronoumn } = useUniversityContext();
  const { showUserProfessors: isUserElgibleToSeeProfessor } = useProfessor();
  const { setDialog } = useDialogContext();
  const { hasFlagNewHome } = useFeatureFlagContext();

  const {
    data: discipline,
    loading: disciplineLoading,
    error: disciplineError,
  } = useContentQueryImmutable(`/disciplines/${disciplineId}`);

  const { data: guide, loading, error } = useContentQueryImmutable(
    `/guides/${guideId}`
  );

  const trackPageAccess = useCallback(
    discipline => {
      trackEvent('Visualizou Disciplina', {
        ...getUTMsByLocation(window.location),
        Universidade: user?.university.name,
        Disciplina: discipline.name,
        Assinante: user?.subscriber ? 'Sim' : 'Não',
        Matéria: discipline.theme.name,
        Freemium: discipline.isFree ? 'sim' : 'não',
        Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      });
    },
    [user, trackEvent, getUTMsByLocation]
  );

  useEffect(() => {
    if (discipline) trackPageAccess(discipline);
  }, [trackPageAccess, discipline]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showLecturesCards = useCallback(lectures => {
    return lectures.length > 0;
  }, []);

  const showListsCategoriesCards = useCallback(discipline => {
    const { listCategories } = discipline;
    return listCategories?.length > 0;
  }, []);

  const professorsWithAtLeastOneList = useCallback(discipline => {
    const filteredProfessors = discipline.professors.filter(
      professor =>
        professor.lists.length > 0 || professor.statementLists.length > 0
    );
    return filteredProfessors;
  }, []);

  const showBookListsCards = useCallback(
    discipline => {
      return professorsWithAtLeastOneList(discipline).length > 0;
    },
    [professorsWithAtLeastOneList]
  );

  const showBooksCards = useCallback(
    (books, discipline) => {
      if (showListsCategoriesCards(discipline)) return false;
      if (showBookListsCards(discipline)) return false;

      return books.length > 0;
    },
    [showListsCategoriesCards, showBookListsCards]
  );

  const { getLecturesFromSubjects, disciplineGuidableName } = useGuide();

  const showFreeTagInGuideHeader = useCallback(
    discipline => {
      return discipline.isFree && showListsCategoriesCards(discipline);
    },
    [showListsCategoriesCards]
  );

  const getAcronymDisciplineUniversity = useCallback(() => {
    const acronym = getAcronymByUniversityName(discipline?.university.name);
    return acronym ? acronym : '';
  }, [discipline]);

  const openInformProfessorDialog = useCallback(() => {
    setDialog(
      <CreateUserProfessorDialog
        defaultThemeId={discipline.theme?.id}
        defaultDisciplineId={discipline.id}
        defaultDisciplineType="Discipline"
        color={discipline.theme?.color}
        source="Página Disciplina Personalizada Professor Não Informado"
      />
    );
  }, [discipline, setDialog]);

  const getCopyFromHeader = useCallback(() => {
    if (!discipline) return '';
    if (!user || !user.university) return discipline.name;

    if (
      user.university.acronym === 'Selecione a universidade' ||
      user.university.acronym === 'Não faço faculdade'
    )
      return `${discipline.name} da sua faculdade`;

    if (!hasFlagNewHome() && isUserElgibleToSeeProfessor()) {
      return (
        <>
          {`${discipline.name} - `}
          <EditableProfessorName onClick={openInformProfessorDialog}>
            Professor não Informado
            <EditIcon />
          </EditableProfessorName>
        </>
      );
    }

    if (user.university.id !== discipline.university.id) {
      const acronym = getAcronymDisciplineUniversity();
      return `${discipline.name} ${acronym}`;
    }

    return `${discipline.name} ${universityPronoumn} ${user.university.acronym} `;
  }, [
    discipline,
    user,
    isUserElgibleToSeeProfessor,
    universityPronoumn,
    openInformProfessorDialog,
    getAcronymDisciplineUniversity,
    hasFlagNewHome,
  ]);

  const getNumColunms = useCallback(
    (lectures, discipline, guide) => {
      let cont = 1;
      if (showLecturesCards(lectures)) cont++;
      if (showListsCategoriesCards(discipline)) cont++;
      if (showBookListsCards(discipline)) cont++;
      if (showBooksCards(guide.books, discipline)) cont++;

      return cont;
    },
    [
      showLecturesCards,
      showListsCategoriesCards,
      showBookListsCards,
      showBooksCards,
    ]
  );

  const lectures = useMemo(() => {
    if (!guide) return [];

    return getLecturesFromSubjects(guide.subjects);
  }, [getLecturesFromSubjects, guide]);

  const shouldAppearBookListsFirst = useMemo(() => {
    if (media.isDesktop()) return false;

    const isCalculusCFromUnifei = disciplineId === '203';
    const isCalculusAFromUnifei = disciplineId === '201';
    const isEDO1FromUnifei = disciplineId === '200';

    return isCalculusCFromUnifei || isCalculusAFromUnifei || isEDO1FromUnifei;
  }, [disciplineId]);

  const getCards = useCallback(() => {
    if (shouldAppearBookListsFirst) {
      return (
        <>
          {showBookListsCards(discipline) && (
            <BookListCards
              professors={professorsWithAtLeastOneList(discipline)}
              acronymDisciplineUniversity={getAcronymDisciplineUniversity()}
              currentPeriod={discipline.currentPeriod}
              disciplineName={discipline.name}
            />
          )}

          <SubjectsCards
            subjects={guide.subjects}
            color={discipline?.theme?.color}
            guideId={guide.id}
            guidableType={disciplineGuidableName}
            name={discipline.name}
            isFree={discipline.isFree}
            shouldHaveFreeTag={!showFreeTagInGuideHeader(discipline)}
          />

          {showLecturesCards(lectures) && (
            <LecturesCards
              lectures={lectures}
              color={discipline?.theme?.color}
              guideId={guide.id}
              shouldHaveFreeTag={
                !showFreeTagInGuideHeader(discipline) && discipline.isFree
              }
            />
          )}

          {showListsCategoriesCards(discipline) && (
            <ListsCategoriesCards
              listCategories={discipline.listCategories}
              guideId={guide.id}
              shouldHaveFreeTag={discipline.isFree}
              theme={discipline.theme}
              acronymDisciplineUniversity={getAcronymDisciplineUniversity()}
            />
          )}

          {showBooksCards(guide.books, discipline) && (
            <BooksCards books={guide.books} discipline={discipline.name} />
          )}
        </>
      );
    }
    return (
      <>
        {showLecturesCards(lectures) && (
          <LecturesCards
            lectures={lectures}
            color={discipline?.theme?.color}
            guideId={guide.id}
            shouldHaveFreeTag={
              !showFreeTagInGuideHeader(discipline) && discipline.isFree
            }
          />
        )}

        <SubjectsCards
          subjects={guide.subjects}
          color={discipline?.theme?.color}
          guideId={guide.id}
          guidableType={disciplineGuidableName}
          name={discipline.name}
          isFree={discipline.isFree}
          shouldHaveFreeTag={!showFreeTagInGuideHeader(discipline)}
        />

        {showListsCategoriesCards(discipline) && (
          <ListsCategoriesCards
            listCategories={discipline.listCategories}
            guideId={guide.id}
            shouldHaveFreeTag={discipline.isFree}
            theme={discipline.theme}
            acronymDisciplineUniversity={getAcronymDisciplineUniversity()}
          />
        )}

        {showBooksCards(guide.books, discipline) && (
          <BooksCards books={guide.books} discipline={discipline.name} />
        )}
        {showBookListsCards(discipline) && (
          <BookListCards
            professors={professorsWithAtLeastOneList(discipline)}
            acronymDisciplineUniversity={getAcronymDisciplineUniversity()}
            currentPeriod={discipline.currentPeriod}
            disciplineName={discipline.name}
          />
        )}
      </>
    );
  }, [
    discipline,
    disciplineGuidableName,
    getAcronymDisciplineUniversity,
    guide,
    lectures,
    shouldAppearBookListsFirst,
    showBookListsCards,
    showBooksCards,
    showFreeTagInGuideHeader,
    showLecturesCards,
    showListsCategoriesCards,
    professorsWithAtLeastOneList,
  ]);

  useRenderDisciplinePageDialog(discipline);

  if (disciplineError || error) return <Error />;
  if (disciplineLoading || loading) return <Loading />;

  return (
    <Container>
      <GuideHeader
        name={getCopyFromHeader()}
        color={discipline.theme.color}
        shouldHaveFreeTag={showFreeTagInGuideHeader(discipline)}
        themeId={discipline.theme.id}
        utmContent={'pag_disciplina'}
      />
      <CardsContent numColumns={getNumColunms(lectures, discipline, guide)}>
        {getCards()}
      </CardsContent>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding-bottom: 100px;
`;

const EditableProfessorName = styled.span`
  text-decoration: underline;
  text-underline-offset: 5px;
  position: relative;
  cursor: pointer;
  &:hover {
    color: #e6e6e6;
    svg {
      color: #e6e6e6;
    }
  }
`;

const EditIcon = styled(FaPen)`
  font-size: 0.8em;
  position: absolute;
  bottom: 4px;
  left: calc(100% + 5px);
  font-weight: lighter;
  color: white;
`;
